<template>
  <div class="inquiry-page">
    <SmallHeader :basic-information="basicInformation" />
    <ShopTabs tab-active-path="/processingServices/processServicesHome" @changeShopPath="changeShopPath" />
    <div class="flow-path" />
    <div class="inquiry-content">
      <h3>物料信息</h3>
      <el-form ref="ruleFormRef" :model="formData" label-width="110px" label-position="left">
        <Table
          class="table-box"
          :item-data="itemData"
          :list-data="formData.materialList"
        >
          <template #goodsName="{ row, index }">
            <el-cascader
              :ref="'cascader' + index"
              v-model="row.goodsName"
              :options="goodsOptions"
              :props="{value:'code',label:'goodsName'}"
              placeholder="请选择品名"
              @change="(val) => selectCategory(val, index)"
            />
          </template>
          <!-- 材质 -->
          <template #goodsMaterialName="{ row, index }">
            <el-select v-model="row.goodsMaterialName" placeholder="请选择" @change="(val) => getGoodsMaterialItem(val, index)" @focus="goodsNameFocus(index)">
              <el-option
                v-for="item in row.goodsMaterialOption"
                :key="item.materials"
                :label="item.materials"
                :value="item.materials"
              />
            </el-select>
          </template>
          <!-- 规格 -->
          <template #goodsSpecName="{ row, index }">
            <el-select v-model="row.goodsSpecName" placeholder="请选择" @change="(val) => getGoodsSpecsItem(val, index)" @focus="goodsNameFocus(index)">
              <el-option
                v-for="item in row.goodsSpecOption"
                :key="item.specs"
                :label="item.specs"
                :value="item.specs"
              />
            </el-select>
          </template>
          <template #manufactor="{ row }">
            <el-input v-model="row.manufactor" />
          </template>
          <template #quantity="{ row, index }">
            <div class="flex quantity">
              <el-input-number v-model="row.quantity" class="number-input" :controls="false" :precision="3" :min="0.001" />
              <el-select v-model="row.unit" placeholder="请选择" @change="(val) => getUnit(val, index)">
                <el-option v-for="item in $store.getters.getDictionaryItem('purchaseUnit')" :key="item.dictId" :label="item.dictName" :value="item.dictId" />
              </el-select>
            </div>
          </template>
          <template #operate="{index}">
            <div class="operate">
              <i v-if="formData.materialList.length < 20" class="add-icon" @click="addtableRow" />
              <i v-if="formData.materialList.length > 1" class="delete-icon" @click="deleteTableROw(index)" />
            </div>
          </template>
        </Table>
        <el-form-item label="物料所在地" prop="materialRegionArr" :rules="rules.selectiveType">
          <el-cascader
            ref="areaRef"
            v-model="formData.materialRegionArr"
            :props="{ label: 'name', value: 'name', children: 'children' }"
            :options="allCityOption"
            @change="getReceivingArea"
          />
        </el-form-item>
        <el-form-item label="具体加工要求" prop="processRequire" class="overspread" :rules="[...rules.message, { min: 1, max: 150, message: '长度为1~150个字符', trigger: 'blur' }]">
          <el-input
            v-model="formData.processRequire"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="请输入具体加工要求"
            maxlength="150"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="附件" class="overspread">
          <!-- attach -->
          <ImageUpload :upload-file-list.sync="formData.attach" />
        </el-form-item>
        <div class="flex">
          <el-form-item label="收货地址" prop="deliveryAddressId" :rules="rules.selectiveType">
            <el-select v-model="formData.deliveryAddressId" placeholder="请选择">
              <el-option
                v-for="item in addressList"
                :key="item.id"
                :label="item.addressName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <div class="receive-address" @click="gotoAddressPage">
            维护收货地址
          </div>
        </div>
        <el-form-item label="期望交期" prop="expectDeliveryTime" :rules="rules.selectiveType">
          <el-date-picker
            v-model="formData.expectDeliveryTime"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          />
        </el-form-item>
        <div class="flex">
          <el-form-item label="联系人" prop="entrustContactPerson" :rules="rules.message">
            <el-input v-model="formData.entrustContactPerson" placeholder="请输入内容" class="input-with-select">
              <el-select slot="append" v-model="formData.entrustContactSex" placeholder="请选择">
                <el-option label="先生" value="01" />
                <el-option label="女士" value="02" />
              </el-select>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="entrustContactPhone" :rules="rules.phone" label-width="10px">
            <el-input
              v-model="formData.entrustContactPhone"
              placeholder="请输入手机号码"
            />
          </el-form-item>
        </div>
      </el-form>
      <div class="submit" :loading="$store.state.loading" @click="submit">
        提交询价
      </div>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { rules } from '@/unit/matchFormRegex'
import { getSelectAreaTree } from '@/http/common'
import ImageUpload from '@/components/ImageUpload.vue'
import SmallHeader from './components/SmallHeader.vue'
import ShopTabs from '@/components/ShopTabs.vue'
import { saveFactoryOrder } from '@/http/processingServices'
import { goodsGetGoodsCategoryList, goodsGetGoodsSpecList, goodsGetGoodsMaterialList } from '@/http/hangOut'
import { queryShopBaseInfoDesc } from '@/http/storeManagement'
import {
  tradeReceivingAddressList
} from '@/http/address'
import { getDictName } from '@/unit/index'
export default {
  components: {
    Table,
    ImageUpload,
    SmallHeader,
    ShopTabs
  },
  data() {
    return {
      getDictName,
      formData: {
        materialList: [{
          goodsMaterialOption: [],
          goodsSpecOption: [],
          unit: '5',
          unitName: '吨'
        }],
        entrustContactSex: '01'
      },
      rules,
      // 地区
      allCityOption: [],
      itemData: [
        { label: '品名 ', prop: 'goodsName', width: 120, required: true },
        { label: '材质 ', prop: 'goodsMaterialName', width: 120, required: true },
        { label: '规格', prop: 'goodsSpecName', width: 120, required: true },
        { label: '产地', prop: 'manufactor', width: 120 },
        { label: '数量 ', prop: 'quantity', width: 120, required: true },
        { label: '操作 ', prop: 'operate', width: 120 }
      ],
      options: [],
      basicInformation: {},
      // 品名集合
      goodsOptions: [],
      // 材质
      goodsMaterialOption: [],
      // 规格
      goodsSpecOption: [],
      // 收货地址
      addressList: [],
      token: null
    }
  },
  created() {
    if (this.$route.query.shopId) {
      // 获取表头的信息
      queryShopBaseInfoDesc(this.$route.query.shopId || '', res => {
        if (res.data) this.basicInformation = { ...res.data }
      })
    }
  },
  mounted() {
    this.token = localStorage.getItem(
      `mallFrontDeskToken${this.$store.state.userCmpRole}`
    )
    if (this.$route.query.id) {
      this.formData.factoryId = this.$route.query.id
    }
    this.getGoodsGetGoodsCategoryList()
    this.getAddressList()
    getSelectAreaTree('00', res => {
      this.allCityOption = [...res.data]
    })
  },
  methods: {
    // 递归处理级联数据
    getTreeData(data) {
      data.map((item) => {
        if (item.children.length === 0) {
          item.children = null
        } else {
          this.getTreeData(item.children)
        }
      })
    },
    // 查询品类
    getGoodsGetGoodsCategoryList() {
      goodsGetGoodsCategoryList(res => {
        this.getTreeData(res.data)
        this.goodsOptions = res.data
      })
    },
    // 材质跟型号的搜索
    associativeSearch(val, index) {
      goodsGetGoodsMaterialList(val, res => {
        this.$set(this.formData.materialList[index], 'goodsMaterialOption', [...res.data])
      })
      goodsGetGoodsSpecList(val, res => {
        this.$set(this.formData.materialList[index], 'goodsSpecOption', [...res.data])
      })
    },
    // 选择商品类别
    selectCategory(item, index) {
      this.formData.materialList[index].goodsTopCategoryId = item[0]
      this.formData.materialList[index].goodsOneCategoryId = item[1]
      this.formData.materialList[index].goodsTwoCategoryId = item[2]
      const node = this.$refs['cascader' + index].getCheckedNodes()[0].pathNodes
      this.formData.materialList[index].goodsTopCategoryName = node.length && node[0].label// 品名一级
      this.formData.materialList[index].goodsOneCategoryName = node.length && node[1] && node[1].label// 二级
      this.formData.materialList[index].goodsTwoCategoryName = node.length && node[2] && node[2].label// 三级
      this.associativeSearch(item[2], index)
      this.$set(this.formData.materialList[index], 'goodsMaterialId', '')
      this.$set(this.formData.materialList[index], 'goodsMaterialName', '')
      this.$set(this.formData.materialList[index], 'goodsSpecId', '')
      this.$set(this.formData.materialList[index], 'goodsSpecName', '')
    },
    // 获取材质
    getGoodsMaterialItem(val, index) {
      this.$set(this.formData.materialList[index], 'goodsMaterialId', val)
    },
    // 获取规格
    getGoodsSpecsItem(val, index) {
      this.$set(this.formData.materialList[index], 'goodsSpecId', val)
    },
    // 材质规格focus
    goodsNameFocus(index) {
      if (!this.formData.materialList[index].goodsTopCategoryId) return this.$message.warning('请先选择品名')
    },
    // 获取地区
    getReceivingArea(val) {
      this.formData.materialProvince = val[0]
      this.formData.materialCity = val[1]
      this.formData.materialRegion = val[2]
      const node = this.$refs['areaRef'].getCheckedNodes()[0].pathNodes
      this.formData.materialProvinceName = node.length && node[0].label// 品名一级
      this.formData.materialCityName = node.length && node[1] && node[1].label// 二级
      this.formData.materialRegionName = node.length && node[2] && node[2].label// 三级
    },
    // 获取所有收货地址
    getAddressList() {
      tradeReceivingAddressList((res) => {
        this.addressList = [...res.data]
        this.addressList.forEach((item) => {
          item.addressName = item.placeProvince + item.placeCity + item.placeTown + item.placeDetailAddr
        })
      })
    },
    // 添加table行
    addtableRow() {
      this.formData.materialList.push({
        goodsMaterialOption: [],
        goodsSpecOption: [],
        unit: '5'
      })
    },
    // 删除 table 行
    deleteTableROw(index) {
      this.formData.materialList.splice(index, 1)
    },
    // 提交
    submit() {
      for (let i = 0; i < this.formData.materialList.length; i++) {
        if (!this.formData.materialList[i].goodsName || !this.formData.materialList[i].goodsMaterialName || !this.formData.materialList[i].goodsSpecName || !this.formData.materialList[i].quantity || !this.formData.materialList[i].unit) {
          return this.$message.warning('请完善物料信息')
        }
        if (this.formData.materialList[i].manufactor && this.formData.materialList[i].manufactor.length > 10) return this.$message.warning('产地长度为10个字符')
      }
      this.$refs['ruleFormRef'].validate((valid) => {
        if (valid) {
          saveFactoryOrder(this.formData, res => {
            this.$confirm('您已成功创建一条加工单，等待商家进行报价', '提交成功', {
              distinguishCancelAndClose: true,
              confirmButtonText: '查看加工单',
              cancelButtonText: '返回商城首页',
              customClass: 'submit-message',
              center: true
            })
              .then(() => {
                window.open(
                  `${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/worksheet/buyerIndex`, '_blank'
                )
              })
              .catch(action => {
                this.$router.push('/')
              })
          })
        }
      })
    },
    // 切换
    changeShopPath(path) {
      if (path === '/processingServices/processServicesHome') return
      this.$router.push({
        path,
        query: {
          id: this.$route.query.id,
          shopId: this.$route.query.shopId
        }
      })
    },
    // 维护收货地址
    gotoAddressPage() {
      window.open(`${this.$projectUrl}login?token=${this.token}&cmpRole=${this.$store.state.userCmpRole}&path=/shippingAddress/index`, '_blank')
    },
    // 单位
    getUnit(val, index) {
      const unitName = getDictName(this.$store.getters.getDictionaryItem('purchaseUnit'), val)
      this.formData.materialList[index].unitName = unitName
      console.log(this.formData.materialList)
    }
  }
}
</script>

<style lang="scss" scoped>
.inquiry-page {
 width: 100%;

  .flow-path {
    width: $centerPlateWidth;
    height: 72px;
    background: url('../../assets/storeManagement/image/flow_path.png') no-repeat top center;
    background-size: 100% 100%;
    margin: 16px auto;
  }

  .inquiry-content {
    background-color: #fff;
    padding: 32px 24px;
    width: $centerPlateWidth;
    margin: 16px auto 100px;
    box-sizing: border-box;

    .table-box {
      margin: 24px 0;
      /deep/ .el-input-group__append {
        width: 60px;
        padding: 0 0;
        .el-select {
          width: 100%;
          .el-input__inner {
            padding: 0 10px;
          }
        }
      }
      .operate {
        i {
          display: inline-block;
          width: 40px;
          height: 40px;
          cursor: pointer;
        }
        .add-icon {
          background: url('../../assets/storeManagement/icon/add_table.png') no-repeat top center;
          background-size: contain;
        }
         .delete-icon {
          background: url('../../assets/storeManagement/icon/delete_table.png') no-repeat top center;
          background-size: contain;
        }
      }
    }
    /deep/.el-form-item__content {
      width: 360px;
      .el-date-editor, .el-cascader, .el-select {
        width: 100%;
      }
    }
    /deep/.overspread {
      .el-form-item__content {
        width: calc(100% - 110px);
      }
    }
    .flex {
      display: flex;
    }
    .submit {
      width: 99px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      color: rgb(255, 255, 255);
      font-size: 14px;
      background-color: $themeColors;
      cursor: pointer;
      margin-left: 110px;
      margin-top: 34px;
    }
  }
  /deep/ .el-input-group__append{
    width: 40px;
  }

  .receive-address {
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    cursor: pointer;
    color: $themeColors;
  }

  .quantity {
    .number-input {
      width: 320px;
      /deep/ .el-input__inner {
        border-radius: 4px 0 0 4px;
      }
    }
    .el-select {
      /deep/.el-input__inner {
        border-radius: 0 4px 4px 0;
        border-color: #DCDFE6;
        border-left: none;
         padding-left: 5px;
         padding-right: 0
      }

    }
  }
}
</style>
